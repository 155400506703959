.opcaoAdicional {
	.botaoAceito {
		width: 100%;
		margin-top: 20px;
	}
}

.heightPage {
	min-height: 950px;
}

.fundo-ini {
	background-color: white;
}

.borda-esq {
	border-left: solid 3px #f4f4f4;
}

.logo-perso {
	width: 200px;
}

.botaoTudo {
	width: 100%;
}

.click {
	cursor: pointer;
}

.inputTelefone > input {
	padding: 0.571rem 1rem;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #d8d6de;
	border-radius: 0.357rem;
}

.Toastify > div > div > div {
	color: #fff !important;
}

.card-maq {
	margin-bottom: 10px;
	border: solid 1px #dcdcdc !important;
	padding: 20px;
	border-radius: 10px;
}

td {
	border-bottom: solid 1px #eeeeee;
	padding-bottom: 7px;
	padding-top: 7px;
	padding-right: 10px;
	padding-left: 10px;
}

.tabela {
	width: 100%;
	padding: 10px;
	border: solid 1px #eeeeee;
	border-bottom: none;
}

.linha {
	color: white;
	background-color: #9c9c9c;
	padding-top: 5px;
	padding-bottom: 5px;
	border-bottom: solid 1px #eeeeee;
}

.ajuste-linha {
	width: 100%;
}

.div-mostrar {
	border-radius: 4px;
	border: solid 1px #dedede;
}

.mostra-filtro {
	cursor: pointer;
}

.aberto {
	transform: rotate(180deg);
	transition: 0.3s;
}

.fechado {
	transform: rotate(0deg);
	transition: 0.3s;
}

//Toast de confirmação
body.react-confirm-alert-body-element {
	overflow: hidden;
}

.react-confirm-alert-blur {
	filter: url(#gaussian-blur);
	filter: blur(2px);
	-webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background: rgba(255, 255, 255, 0.9);
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
	opacity: 0;
	-webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	-moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	-o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
	font-family: Arial, Helvetica, sans-serif;
	width: 400px;
	padding: 30px;
	text-align: left;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
	color: #666;
}

.react-confirm-alert-svg {
	position: absolute;
	top: 0;
	left: 0;
}

.react-confirm-alert-body > h1 {
	margin-top: 0;
	font-size: 20px;
}

.react-confirm-alert-body > h3 {
	margin: 0;
	font-size: 16px;
}

.react-confirm-alert-button-group {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
}

.react-confirm-alert-button-group > button {
	outline: none;
	background: #333;
	border: none;
	display: inline-block;
	padding: 6px 18px;
	color: #eee;
	margin-right: 10px;
	border-radius: 5px;
	font-size: 12px;
	cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-moz-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-o-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

//pagina detalhes maquina

.anexo-card:hover {
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 16px;
	font-weight: 500;
	transition: 0.2s;
	display: flex;
	justify-content: space-between;
	border-radius: 6px;
	padding-left: 20px;
	background-color: #e5e5e5;
}

.anexo-card {
	transition: 0.2s;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 16px;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	border-radius: 6px;
	padding-left: 20px;
	background-color: #ececec;
}

.card-maq-comp {
	background-color: white;
	margin-bottom: 10px;
	border: solid 1px #dcdcdc !important;
	border-radius: 10px;
}

.card-maq-comp:hover {
	background-color: #f3f3f3;
}

.filter {
	&__menu {
		margin: 0.125rem auto;
	}

	&__option {
		background-color: white;

		&--is-focused {
			background-color: #d7d7d7;
		}
	}

	&__group {
		padding: 0;
	}

	&__menu-portal {
		border: 1px solid #1e1e1e !important;
	}
}

.img-comp {
	width: 100%;
}

.card-bg-img {
	background-color: white;
	border-radius: 10px 0 0 10px;
}

.estilo-borda-pendente {
	border-left: solid orange;
	padding-left: 10px;
}

.estilo-borda-normal {
	border-left: solid #05b02d;
	padding-left: 10px;
}

.sec-maquinas {
	background-color: #efefef;
	padding: 20px;
	height: 300px;
	overflow-y: scroll;
}

.info-card {
	border: solid 1px #e8e8e8;
	padding: 10px;
	border-radius: 3px;
}

.titulo-indo-maq {
	font-size: 13px;
	color: black;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
}

.sub-info {
	font-size: 11px;
	font-weight: 700;
	color: black;
}

.value-info {
	font-size: 14px;
	font-weight: 500;
	color: #4b4b4b;
}

.bloco-info {
	margin-bottom: 10px;
}

.bloco-info-2 {
	border: solid 1px #dedede;
	padding: 10px;
	margin-top: 5px;
	margin-bottom: 7px;
}

.btn-edit-maq {
	font-size: 13px;
	font-family: Poppins, sans-serif;
	color: black !important;
	background-color: white;
	padding: 5px 20px 5px;
}

.estilo-custo-comp {
	font-size: 13px;
	color: black;
}

.btn-classic-style {
	transition: 0.2s ease-in-out;
	padding: 7px;
	font-size: 12px !important;
	background-color: white !important;
	color: black !important;
	border: solid 1px #c7c7c7 !important;
	box-shadow: none !important;
}

.btn-classic-style:active {
	padding: 7px;
	font-size: 12px !important;
	background-color: white !important;
	color: black !important;
	border: solid 1px #c7c7c7 !important;
	box-shadow: none !important;
}

.btn-classic-style:focus {
	padding: 7px;
	font-size: 12px !important;
	background-color: white !important;
	color: black !important;
	border: solid 1px #c7c7c7 !important;
	box-shadow: none !important;
}

.btn-classic-style:hover {
	padding: 7px;
	transition: 0.2s;
	font-size: 12px !important;
	background-color: #ffc400 !important;
	color: black !important;
	border: solid 1px #ffc400 !important;
	box-shadow: none !important;
}

.info-id-maquina {
	color: black;
	font-weight: 600;
	font-size: 12px;
	margin-bottom: 5px;
}

.estilo-btn-back {
	box-shadow: none !important;
	border: solid 1px #c7c7c7 !important;
	background-color: white !important;
}

.estilo-btn-back:active {
	box-shadow: none !important;
	border: solid 1px #c7c7c7 !important;
	background-color: transparent !important;
}

.estilo-btn-back:focus {
	box-shadow: none !important;
	border: solid 1px #c7c7c7 !important;
	background-color: transparent !important;
}

.anexo-text {
	font-size: 14px;
	color: black;
}

//row clicavel
.react-dataTable .rdt_TableBody .rdt_TableRow {
	color: #6e6b7b;
	cursor: pointer;
}

//Pagina pedidos
.tabelo-header {
	font-size: 14px;
	font-family: Poppins, sans-serif;
	font-weight: 500;
	color: black;
}

//página aprovação
.card-aprovacao {
	background-color: #ffffff;
	border: solid 1px #cecece;
}

//página aprovação
.aprovado {
	background-color: #12742f26;
}

.reprovado {
	background-color: #74121226;
}

//pagina logs

.space-padrao {
	padding-top: 10px;
	padding-bottom: 10px;
}

.space-padrao-2 {
	padding-top: 7px;
	padding-bottom: 7px;
}

// custom card obs
// obsCardAll
// obsLoop
// obsObs
// obsFotter
// obsData
// obsUser

.obsCardAll {
	overflow-y: auto;
	max-height: 200px;
}
li.sidebar-group-active > a {
	background: #000 !important;
}

.input-maq {
	background-color: #ebebeb;
	border-radius: 10px;
	border: solid 1px #dedede00;
}

.apexcharts-canvas .apexcharts-text {
	fill: #fff !important;
}
.obsLoop {
	background-color: #f9ea7a;
	border-radius: 10px;
	padding: 7px 0 7px 0;
	margin-top: 10px;
}

.obsObs {
	font-size: 14px;
	color: black;
	padding: 5px;
}

.react-select__multi-value .select__multi-value__label,
.select__multi-value .select__multi-value__label {
	color: #fff;
	background-color: #363636;
	font-size: 0.85rem;
}
[dir='ltr'] .react-select__multi-value .select__multi-value__remove,
[dir='ltr'] .select__multi-value .select__multi-value__remove {
	padding-left: 0;
	padding-right: 0.5rem;
	background-color: #a00000;
}

.obsFotter {
	font-size: 14px;
	color: #ffffff;
	display: flex;
	justify-content: space-between;
	background-color: #202020;
	padding: 5px 10px 0px 10px;
	border-radius: 12px 12px 0 0;
}

.obsData {
	font-size: 12px;
}

.obsUser {
	font-size: 12px;
}

.vertical-layout.vertical-menu-modern.menu-expanded .main-menu {
	width: 260px;
	background-color: #0a0a0a;
}
.main-menu.menu-light .navigation {
	background: #0a0a0a;
}
.main-menu.menu-light .navigation li a > * {
	color: #fff;
}
.main-menu.menu-light .navigation > li.active > a {
	background: linear-gradient(
		270deg,
		rgba(253, 220, 98, 0.78) 0%,
		rgb(253, 220, 98) 100%
	);
	color: #fff;
}
.apexcharts-canvas .apexcharts-text,
.apexcharts-canvas .apexcharts-tooltip-text,
.apexcharts-canvas .apexcharts-datalabel-label,
.apexcharts-canvas .apexcharts-datalabel {
	font-family: var(--bs-font-sans-serif) !important;
	fill: #fff;
	font-weight: 400;
	filter: none;
}
.apexcharts-menu-item {
	padding: 6px 7px;
	color: #000;
	font-size: 12px;
	cursor: pointer;
}
.navbar-floating .navbar-container:not(.main-menu-content) {
	padding: 0.8rem 1rem;
	border-radius: 10px !important;
	background-color: #1a1a1a;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
.header-navbar
	.navbar-container
	ul.navbar-nav
	li
	a.dropdown-user-link
	.user-name {
	margin-left: 0.2rem;
	color: #fff;
}
.header-navbar
	.navbar-container
	ul.navbar-nav
	li
	a.dropdown-user-link
	.user-status {
	font-size: smaller;
	color: #fff;
}
